

/* -------------------------------------------------------------------------- */
/*                                     API                                    */
/* -------------------------------------------------------------------------- */

const baseURI = `https://mesto-react.lehano.ru/api`

const dataApi = {

  myProfileURI: `${baseURI}/users/me`,

  cardsURI: `${baseURI}/cards`,

  avatarProfileURI: `${baseURI}/users/me/avatar`,

  likesURI: `${baseURI}/cards/likes`,

  logoutURI: `${baseURI}/users/logout`,
}


export { baseURI, dataApi,  }