// КЛАСС ДЛЯ ВЗАИМОДЕЙСТВИЯ С СЕРВЕРОМ
import { dataApi } from "./constants";

class Api {

  constructor(dataApi) {
    this._myProfileURI = dataApi.myProfileURI; // URI ДАННЫХ ПРОФАЙЛА
    this._cardsURI = dataApi.cardsURI; // URL МАССИВА ОБЪЕКТОВ КАРТОЧЕК
    this._likesURI = dataApi.likesURI; // URL МАССИВА ОБЪЕКТОВ ЛАЙКОВ КАРТОЧКИ
    this._avatarURI = dataApi.avatarProfileURI; // URL АВАТАРА ПРОФАЙЛА
    this._logoutURI = dataApi.logoutURI;
  }


  // ПОЛУЧЕНИЕ ДАННЫХ ПРОФАЙЛА С СЕРВЕРА
  getUserInfo() {
    return fetch(this._myProfileURI, {
      withCredentials: true,
      credentials: 'include',
    })
      .then((result) => { return this._getResponse(result) })
  };


  // ПОЛУЧЕНИЕ МАССИВА ПЕРВОНАЧАЛЬНЫХ ДАННЫХ КАРТОЧЕК С СЕРВЕРА
  getDataInitialCards() {
    return fetch(this._cardsURI, {
      withCredentials: true,
      credentials: 'include',
    })
      .then((result) => { return this._getResponse(result); })
  };


  // ПЕРЕДАЧА ИЗМЕНЁННЫХ ДАННЫХ ПРОФАЙЛА НА СЕРВЕР
  formEditDataProfile(data) {

    return fetch(this._myProfileURI, {
      withCredentials: true,
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: data.name,
        about: data.about
      })
    })
      .then((result) => {
        return this._getResponse(result);
      })
  };


  // ПЕРЕДАЧА ДАННЫХ НОВОЙ КАРТОЧКИ НА СЕРВЕР
  sendDataNewCardAtServer(inputsValues) {

    return fetch(this._cardsURI, {
      withCredentials: true,
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: inputsValues.name,
        link: inputsValues.link
      })
    })
      .then((result) => {
        return this._getResponse(result);
      })
  };


  // ЗАПРОС НА УДАЛЕНИЕ КАРТОЧКИ С СЕРВЕРА
  deleteCardFromServer(idCard) {

    return fetch(`${this._cardsURI}/${idCard}`, {
      withCredentials: true,
      credentials: 'include',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        _id: idCard
      })
    })
      .then((result) => {
        return this._getResponse(result);
      })
  };



  // ЗАПРОС НА УВЕЛИЧЕНИЕ ЧИСЛА ЛАЙКОВ У КАРТОЧКИ
  plusNumberLikes(idCard) {

    return fetch(`${this._cardsURI}/${idCard}/likes`, {
      withCredentials: true,
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-Type': "application/json"
      },
      body: JSON.stringify({
        likes: +1
      })
    })
      .then((result) => {
        return this._getResponse(result);
      })
  };



  // ЗАПРОС НА УМЕНЬШЕНИЕ ЧИСЛА ЛАЙКОВ У КАРТОЧКИ
  deleteLikes(idCard) {

    return fetch(`${this._cardsURI}/${idCard}/likes`, {
      withCredentials: true,
      credentials: 'include',
      method: 'DELETE',
      headers: {
        'Content-Type': "application/json"
      },
      body: JSON.stringify({
        likes: -1
      })
    })
      .then((result) => {
        return this._getResponse(result);
      })
  };


  // ЗАПРОС НА ИЗМЕНЕНИЕ АВАТАРА
  changeAvatarProfile(url) {
    return fetch(this._avatarURI, {
      withCredentials: true,
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        avatar: url.avatar,
      })
    })
      .then((result) => {
        return this._getResponse(result);
      })
  };

  // ВЫХОД ИЗ СИСТЕМЫ (УДАЛЕНИЕ ТОКЕНА ИЗ КУК)
  logoutUser() {
    return fetch(this._logoutURI, {
      withCredentials: true,
      credentials: 'include'
    })
      .then((result) => {
        return this._getResponse(result);
      })
      .then((res) => { console.log(res) })
  };


  // МЕТОД ПОЛУЧЕНИЯ ОТВЕТА ОТ СЕРВЕРА
  _getResponse(result) {

    if (!result.ok) {
      return Promise.reject(`Ошибка: ${result.status}`);
    } else {
      return result.json();
    }
  };

};

const api = new Api(dataApi)

export default api;


