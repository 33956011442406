import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom'



function PageNotFound({ handleSetIsPageNotFound }) {

    useEffect(() => { handleSetIsPageNotFound() }, []);

    const history = useHistory();

    function stepAtMainPage() {
        history.push('/')
    };

    return (
        <div className="pagenotfound">
            <p>404 - такой страницы у нас не имеется 😖</p>
            <p>Попробуйте начать с <Link className="pagenotfound__link" onClick={stepAtMainPage}>главной</Link></p>
        </div>
    )
}

export default PageNotFound;