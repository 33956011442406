import React, { useEffect, useState } from 'react';
import './../index.css';
import Header from './Header.js';
import Main from './Main.js';
import Footer from './Footer.js';
import ImagePopup from './ImagePopup.js';
import api from './../utils/api.js';
import { CurrentUserContext } from './../contexts/CurrentUserContext.js';
import { LoggedInContext } from '../contexts/LoggedInContext.js';
import EditProfilePopup from './EditProfilePopup.js';
import EditAvatarPopup from './EditAvatarPopup.js';
import AddPlacePopup from './AddPlacePopup.js';
import AreYouSurePopup from './AreYouSurePopup.js';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute.js';
import Login from './Login.js';
import Register from './Register.js';
import InfoToolTipPopup from './InfoToolTipPopup.js';
import authApi from './../utils/auth.js';
import PageNotFound from './PageNotFound.js';


function App() {

  const [isEditProfilePopupOpen, setIsEditProfilePopupOpen] = useState(false);
  const [isEditAvatarPopupOpen, setIsEditAvatarPopupOpen] = useState(false);
  const [isAddPlacePopupOpen, setIsAddPlacePopupOpen] = useState(false);
  const [isAreYouSurePopupOpen, setIsAreYouSurePopupOpen] = useState(false);

  const [currentUser, setCurrentUser] = useState({});

  const [cards, setCards] = useState([]);
  const [dataCardFromAreYouSure, setDataCardFromAreYouSure] = useState({})
  const [selectedCard, setSelectedCard] = useState({});

  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isEntryOpen, setIsEntryOpen] = useState(false);

  const [isInfoToolTipPopup, setIsInfoToolTipPopup] = useState({});

  const [dataUserRegister, setDataUserRegister] = useState({ email: '', password: '' });
  const [dataUserAuth, setDataUserAuth] = useState({ email: '', password: '' });
  const [emailHeader, setEmailHeader] = useState('');

  const [isLoggedIn, setLoggedIn] = useState(false);

  const [isPageNotFound, setIsPageNotFound] = useState(false);

  const history = useHistory();


  // ЕСЛИ ПОЛУЧИЛИ ТОКЕН (ЗАПИСАЛИ EMAIL В LOCALSTORAGE), ЗНАЧИТ АВТОРИЗОВАЛИСЬ И
  // МОЖНО ЗАКРЫВАТЬ ФОРМЫ + МЕНЯТЬ СТАТУС НА "ЗАЛОГИНЕН"
  useEffect(() => {
    if (localStorage.getItem('userEmail')) {
      handleEmailHeader(localStorage.getItem('userEmail'));
      handleIsEntryClose();
      handleIsRegisterClose();
      handleLoggedIn();
    }

  }, []);


  // ПОЛУЧЕНИЕ ДАННЫХ ПРОФАЙЛА ОТ СЕРВЕРА
  useEffect(() => {
    if (localStorage.getItem('userEmail')) {
      api.getUserInfo()
        .then((res) => {
          return handleSetCurrentUser(res);
        })
        .catch((err) => { console.log('Ошибка: ', err) })
    }
  }, []);


  // ПОЛУЧЕНИЕ ДАННЫХ КАРТОЧЕК ОТ СЕРВЕРА
  useEffect(() => {
    if (localStorage.getItem('userEmail')) {
      handleInitialCards()
    }
  }, []);



  // ЗАПРОС НЕИЗВЕСТНОЙ СТРАНИЦЫ
  function handleSetIsPageNotFound() {
    setIsPageNotFound(true)
  };



  function handleSetIsNotPageNotFound() {
    setIsPageNotFound(false)
  };



  // ОБРАБОТЧИК email В Header
  function handleEmailHeader(data) {
    setEmailHeader(data)
  };



  // ОБРАБОТЧИК ЛОГАУТА
  function handleLogout() {
    handleRemoveJwtFromCookie();
    localStorage.removeItem('userEmail');
    handleNotLoggedIn();
    handleEmailHeader('');
  };



  // ОБРАБОТЧИК УДАЛЕНИЯ ТОКЕНА ИЗ КУК
  function handleRemoveJwtFromCookie() {
    api.logoutUser();
  };



  // ОБРАБОТЧИК УСПЕШНОГО СТАТУСА АВТОРИЗАЦИИ
  function handleLoggedIn() {
    setLoggedIn(true)
  };



  // ОБРАБОТЧИК НЕУСПЕШНОГО СТАТУСА АВТОРИЗАЦИИ
  function handleNotLoggedIn() {
    setLoggedIn(false)
  };



  // ОБРАБОТЧИК ФОРМЫ АВТОРИЗАЦИИ
  function handleDataUserAuth(e) {
    const { name, value } = e.target;

    setDataUserAuth({
      ...dataUserAuth,
      [name]: value
    })
  };



  // СТРАНИЦА С ФОРМОЙ АВТОРИЗАЦИИ ОТКРЫТА
  function handleIsEntryOpen() {
    setIsEntryOpen(true);
  };



  // СТРАНИЦА С ФОРМОЙ АВТОРИЗАЦИИ ЗАКРЫТА
  const handleIsEntryClose = () => {
    setIsEntryOpen(false);
  };



  // ПРИШЛИ НА СТРАНИЦУ С ФОРМОЙ РЕГИСТРАЦИИ
  function handleIsRegisterOpen() {
    setIsRegisterOpen(true);

  };



  // УШЛИ СО СТРАНИЦЫ С ФОРМОЙ РЕГИСТРАЦИИ
  function handleIsRegisterClose() {
    setIsRegisterOpen(false);
  };



  // ОБРАБОТЧИК ФОРМЫ РЕГИСТРАЦИИ
  function handleDataUserRegister(e) {

    const { name, value } = e.target;

    setDataUserRegister({
      ...dataUserRegister,
      [name]: value
    })
  };



  // ОБРАБОТЧИК КЛИКА НА КНОПКУ ПРОФАЙЛА
  function handleEditProfileClick() {
    setIsEditProfilePopupOpen(true);
  };



  // ОБРАБОТЧИК КЛИКА НА АВАТАР
  function handleEditAvatarClick() {
    setIsEditAvatarPopupOpen(true);
  };



  // ОБРАБОТЧИК КЛИКА НА КНОПКУ ДОБАВЛЕНИЯ НОВОЙ КАРТОЧКИ
  function handleAddPlaceClick() {
    setIsAddPlacePopupOpen(true);
  };



  // ОБРАБОТЧИК КЛИКА НА КАРТИНКУ В КАРТОЧКЕ
  function handleCardClick(cardInfo) {
    setSelectedCard(cardInfo);
  };



  // ОБРАБОТЧИК ЗАКРЫТИЯ ВСЕХ ПОПАПОВ
  function closeAllPopups() {
    setIsEditProfilePopupOpen(false);
    setIsEditAvatarPopupOpen(false);
    setIsAddPlacePopupOpen(false);
    setIsAreYouSurePopupOpen(false);
    setSelectedCard({});
    setIsInfoToolTipPopup({});
  };



  // ОБРАБОТЧИК ДАННЫХ ТЕКУЩЕГО ПОЛЬЗОВАТЕЛЯ
  function handleSetCurrentUser(data) {
    setCurrentUser(data);
  };



  // ОБРАБОТЧИК ПОЛУЧЕНИЯ КАРТОЧЕК С СЕРВЕРА
  function handleInitialCards() {
    api.getDataInitialCards()
      .then((result) => {
        setCards(result.data.reverse())
      })
      .catch((err) => { console.log('Ошибка: ', err) })
  };



  // ОБРАБОТЧИК ОБНОВЛЕНИЯ ДАННЫХ ПРОФАЙЛА
  function handleUpdateUser(data) {
    api.formEditDataProfile(data)
      .then((res) => {
        handleSetCurrentUser(res);
        closeAllPopups();
      })
      .catch((err) => { console.log('Ошибка: ', err) })
  };



  // ОБРАБОТЧИК ОБНОВЛЕНИЯ АВАТАРА
  function handleUpdateAvatar(url) {

    api.changeAvatarProfile(url)
      .then((res) => {
        handleSetCurrentUser(res);
        closeAllPopups();
      })
      .catch((err) => { console.log('Ошибка: ', err) })
  };



  // ОБРАБОТЧИК ДОБАВЛЕНИЯ НОВОЙ КАРТОЧКИ
  function handleAddPlaceSubmit(data, setName, setLink) {

    api.sendDataNewCardAtServer(data)
      .then((res) => {
        setCards([res, ...cards])
        closeAllPopups();
        setName('');
        setLink('');
      })
      .catch((err) => { console.log('Ошибка: ', err) })
  };



  // ОБРАБОТЧИК ЛАЙКА/ДИЗЛАЙКА
  function handleCardLike(card) {

    //ПРОВЕРКА ЦЕЛЕВОЙ КАРТОЧКИ НА НАЛИЧИЕ МОЕГО ЛАЙКА
    const isLiked = card.likes.some((ownerCard) => { return ownerCard === currentUser._id });

    // ЕСЛИ ЛАЙК УЖЕ ЕСТЬ, ТО ЕГО УБИРАЕМ
    if (isLiked === true || isLiked === undefined) {

      const apiLike = api.deleteLikes(card._id)
      apiLikeDislike(apiLike, card)

      // ЕСЛИ ЛАЙКА НЕТ, ТО ЕГО ДОБАВЛЯЕМ
    } else {
      const apiDislike = api.plusNumberLikes(card._id)
      apiLikeDislike(apiDislike, card)
    }
  };



  // ЗАПРОС К СЕРВЕРУ НА ЛАЙК/ДИЗЛАЙК (ШАБЛОН)
  function apiLikeDislike(urlApi, card) {
    urlApi
      .then((targetCard) => {
        setCards(cards.map((cardFromArray) => cardFromArray._id === card._id ? targetCard : cardFromArray))
      })
      .catch((err) => { console.log('Ошибка: ', err) })

  };



  // ОБРАБОТЧИК УДАЛЕНИЯ КАРТОЧКИ
  function handleCardDelete(card) {
    const isOwn = card.owner === currentUser._id;

    if (isOwn === true) {
      api.deleteCardFromServer(card._id)
        .then(() => {
          setCards(cards.filter((cardFromArray) => cardFromArray._id !== card._id))
          closeAllPopups();
        })
        .catch((err) => { console.log('Ошибка: ', err) })
    }
  };



  // ОБРАБОТЧИК ОТКРЫТИЯ ПОПАПА "ВЫ УВЕРЕНЫ?"
  function handleAreYouSure() {
    setIsAreYouSurePopupOpen(true)
  };



  // ДАННЫЕ КАРТОЧКИ ПОЛУЧЕННЫЕ ИЗ Card ПРИ ОТКРЫТИИ ПОПАПА AreYouSure
  function handleDataAreYouSure(data) {
    setDataCardFromAreYouSure(data)
  };



  // ОБРАБОТЧИК УДАЛЕНИЯ КАРТОЧКИ ЧЕРЕЗ AreYouSure
  function handleCardDeleteAreYouSure() {
    dataCardFromAreYouSure.onCardDelete(dataCardFromAreYouSure)
    setDataCardFromAreYouSure({})
  };



  // ОБРАБОТЧИК ПОПАПА ИНФОРМАЦИИ, ПОСЛЕ (НЕ)УСПЕШНОЙ РЕГИСТРАЦИИ ИЛИ
  // НЕУСПЕШНОГО ЗАЛОГИНИВАНИЯ
  function handleInfoToolTip(isOpen, isError, successFail) {
    setIsInfoToolTipPopup({
      open: isOpen,
      error: isError,
      image: successFail
    })
  };



  // ПЕРЕАДРЕСАЦИЯ НА ГЛАВНУЮ СТРАНИЦУ
  function stepAtTheMainPage() {
    handleSetIsNotPageNotFound();
    history.push('/')
  };



  // ОБРАБОТЧИК ФОРМЫ АВТОРИЗАЦИИ
  function handleSubmitAuth(e) {
    e.preventDefault();

    const { password, email } = dataUserAuth;

    authApi.authorization(password, email)
      .then((res) => {
        localStorage.setItem('userEmail', res.user.email);
        handleSetCurrentUser(res.user);
        handleIsEntryClose(); // ФОРМА АВТОРИЗАЦИИ ЗАКРЫТА
        handleLoggedIn(); // СТАТУС СТЭЙТА "АВТОРИЗОВАН"
        handleEmailHeader(res.user.email);
        handleInitialCards();
        stepAtTheMainPage();
      })

      .catch((err) => {
        handleInfoToolTip(true, true, 'fail')
        console.log('Ошибка: ', err)
      })
  };



  // ОБРАБОТЧИК ФОРМЫ РЕГИСТРАЦИИ
  function handleSubmitRegistration(e) {
    e.preventDefault();

    const { password, email } = dataUserRegister;

    authApi.registration(password, email)
      .then(() => {
        setDataUserRegister({
          email: '',
          password: ''
        });
        handleInfoToolTip(true, false, 'success');
      })

      .catch((err) => {
        handleInfoToolTip(true, true, 'fail')
        console.log('Ошибка: ', err)
      })
  };

  return (
    <LoggedInContext.Provider value={isLoggedIn}>
      <CurrentUserContext.Provider value={currentUser}>

        <div className="page__container">
          <Header
            isPageNotFound={isPageNotFound}
            isRegisterOpen={isRegisterOpen}
            isEntryOpen={isEntryOpen}
            handleIsEntryOpen={handleIsEntryOpen}
            handleIsRegisterOpen={handleIsRegisterOpen}
            handleLogout={handleLogout}
            handleEmailHeader={emailHeader}
            isLoggedIn={isLoggedIn}
          />

          <Switch>

            <ProtectedRoute exact path="/">

              <Main
                onEditProfile={handleEditProfileClick}
                onAddPlace={handleAddPlaceClick}
                onEditAvatar={handleEditAvatarClick}
                onCardClick={handleCardClick}
                cards={cards}
                onCardLike={handleCardLike}
                onCardDelete={handleCardDelete}
                onAgreeDelete={handleAreYouSure}
                dataFromAreYouSure={handleDataAreYouSure}
              />

              <ImagePopup
                card={selectedCard}
                onClose={closeAllPopups}
              />

              <EditProfilePopup
                isOpen={isEditProfilePopupOpen}
                onClose={closeAllPopups}
                onUpdateUser={handleUpdateUser}
              />

              <EditAvatarPopup
                isOpen={isEditAvatarPopupOpen}
                onClose={closeAllPopups}
                onUpdateAvatar={handleUpdateAvatar}
              />

              <AddPlacePopup
                isOpen={isAddPlacePopupOpen}
                onClose={closeAllPopups}
                onAddPlace={handleAddPlaceSubmit}
              />

              <AreYouSurePopup
                isOpen={isAreYouSurePopupOpen}
                onClose={closeAllPopups}
                onDelete={handleCardDeleteAreYouSure}
              />

              <Footer />

            </ProtectedRoute>

            <Route path="/signin">
              {!isLoggedIn ?
                <Login
                  onIsEntryOpen={handleIsEntryOpen}
                  onIsRegisterClose={handleIsRegisterClose}
                  onDataUserAuth={handleDataUserAuth}
                  dataUserAuth={dataUserAuth}
                  onSubmitAuth={handleSubmitAuth}
                /> : <Redirect to="/" />}
            </Route>


            <Route path="/signup">
              <Register
                onIsRegisterClose={handleIsRegisterClose}
                onIsRegisterOpen={handleIsRegisterOpen}
                onIsEntryClose={handleIsEntryClose}
                onDataUserRegister={handleDataUserRegister}
                dataUserRegister={dataUserRegister}
                onSubminRegister={handleSubmitRegistration}
              />
            </Route>


            <Route path="*">
              <PageNotFound
                handleSetIsPageNotFound={handleSetIsPageNotFound}
              />
            </Route>


          </Switch>

          <InfoToolTipPopup
            isInfoToolTip={isInfoToolTipPopup}
            onClose={closeAllPopups}
          />

        </div>

      </CurrentUserContext.Provider>
    </LoggedInContext.Provider>
  );
}

export default App;
