import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';


const Register = ({
    onIsRegisterOpen,
    onIsRegisterClose,
    onIsEntryClose,
    onDataUserRegister,
    dataUserRegister,
    onSubminRegister
}) => {


    // ПРИ ОТРИСОВКЕ КОМПОНЕНТА МЕНЯЕМ СТАТУСЫ СТЭЙТОВ
    useEffect(() => {
        onIsEntryClose()
        onIsRegisterOpen();
    }, [])



    return (
        <div className="login">

            <h2 className="login__title">Регистрация</h2>

            <form onSubmit={onSubminRegister} className="login__container-input">

                <label className="popup__box-input-span">
                    <input value={dataUserRegister.email} onChange={onDataUserRegister} id="email" name="email" type="email" className="login__input" placeholder="Email" autoComplete="off"></input>
                    <span className="popup__input-error email-register-error"></span>
                </label>

                <label className="popup__box-input-span">
                    <input value={dataUserRegister.password} onChange={onDataUserRegister} id="password" name="password" type="password" className="login__input" minLength={5} placeholder="Пароль" autoComplete="off"></input>
                    <span className="popup__input-error password-register-error"></span>
                </label>

                <button className="login__button">Зарегистрироваться</button>

            </form>

            <div className="registration__container-already-registered">
                <p className="registration__already-registered">Уже зарегистрированы?</p>
                <Link onClick={onIsRegisterClose} className="registration__entry" to="/signin">Войти</Link>
            </div>
        </div>
    )
}

export default Register;