import React from 'react';
import logo from './../images/logo.svg';
import { Link } from 'react-router-dom'


const Header = ({
    isRegisterOpen,
    isEntryOpen,
    handleIsEntryOpen,
    handleIsRegisterOpen,
    handleLogout,
    handleEmailHeader,
    isPageNotFound,
    isLoggedIn,
}) => {

    // ОПРЕДЕЛЕНИЕ ЗАГОЛОВКА ДЕЙСТВИЯ В ШАПКЕ - "Регистрация", "Войти" или "Выйти"
    function handleLogRegOutTitle() {

        if (isEntryOpen && !localStorage.getItem('userEmail')) {
            return <Link onClick={handleIsRegisterOpen} to="/signup" className="header__log-reg-out">Регистрация</Link>
        }

        else
            if ((isRegisterOpen || isPageNotFound) && !isLoggedIn) {
                return <Link onClick={handleIsEntryOpen} to="/signin" className="header__log-reg-out">Войти</Link>
            }

            else
                if ((!isEntryOpen && !isRegisterOpen && !isPageNotFound) || (isPageNotFound && isLoggedIn)) {
                    return <Link onClick={handleLogout} to="/signin" className="header__log-reg-out">Выйти</Link>
                }
    }

    return (
        <header className="header">
            <img src={logo} className="header__logo" alt="Логотип сайта" />
            <div className="header__container-log-reg-out">
                <p className="header__log-reg-out-email">{handleEmailHeader}</p>
                {handleLogRegOutTitle()}
            </div>
        </header>


    )
}




export default Header;