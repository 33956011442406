import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LoggedInContext } from '../contexts/LoggedInContext.js'


const ProtectedRoute = (props) => {

    const isLoggedIn = React.useContext(LoggedInContext)

    return (
        <Route>
            {isLoggedIn ? props.children : <Redirect to='/signin' />}
        </Route>
    )

}

export default ProtectedRoute;