import { baseURI } from "./constants";


class AuthApi {
    constructor(baseURI) {
        this._baseURI= baseURI
    }


    registration(password, email) {
        return fetch(`${this._baseURI}/signup`,
            {
                withCredentials: true,
                credentials: 'include',
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    password: `${password}`,
                    email: `${email}`,
                })
            })
            .then((result) => { return this._getResponse(result) })
    }


    authorization(password, email) {
        return fetch(`${this._baseURI}/signin`,
            {
                withCredentials: true,
                credentials: 'include',
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    password: `${password}`,
                    email: `${email}`,
                })
            })
            .then((result) => { return this._getResponse(result) })
    }


    _getResponse(result) {
        if (!result.ok) {
            return Promise.reject(`Ошибка: ${result.status}`);
        } else {
            return result.json();
        }
    }
}

const authApi = new AuthApi(baseURI);

export default authApi;

